:root {
  --black: #263238;
  --grey: #a9a9a9;
  --lightgrey: #f5f5f5;
  --lightblue: #3E95BA;
  --font-sans: "Yusei Magic", "Noto Color Emoji", system-ui, sans-serif;
  --font-mono: "Noto Sans Mono", monospace;
  --font-size: 10vw;
}

*, *:before, *:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

*:focus {
  outline: none;
}

html {
  font-family: var(--font-sans);
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

main {
  width: 100dvw;
  height: 100dvh;
  text-align: center;
}

p {
  line-height: var(--line);
}

div.page {
  width: 100dvw;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
}

div.page {
  display: none;
}

div.page.active {
  display: flex;
}

nav {
  position: absolute;
  top: 8px;
  left: 0;
  width: 100dvw;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  margin: 0 auto;
  width: 250px;
  height: 54px;
  font-size: 0;
  line-height: 0;
  border: 2px solid var(--grey);
  background: #fff;
  border-radius: 50px;
}

@media (hover: hover) {
  .buttonContainer {
    opacity: 0.2;
    transition: opacity 0.5s;
  }
  .buttonContainer:hover {
    opacity: 1;
  }
}

button {
  margin: 0 15px;
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50px;
  color: var(--black);
  background: none;
  cursor: pointer;
  position: relative;
  transition: color 0.5s, border 0.5s;
}

@media (hover: hover) {
  button {
    color: var(--grey);
  }
  button:hover {
    color: var(--black);
  }
}

textarea {
  font-family: var(--font-mono);
  font-size: 18px;
  border: 1px dashed var(--grey);
  border-radius: 5px;
  padding: 10px;
  position: sticky;
  margin: 70px 0 auto;
  width: 800px;
  max-width: 96dvw;
  height: calc(100dvh - 85px);
  color: var(--black);
  caret-color: var(--lightblue);
  background-color: white;
  overflow-y: auto;
  overflow-x: auto;
}

@media print {
  div.page {
    display: flex;
    page-break-before: always;
    overflow: hidden;
  }
  nav {
    display: none;
  }
}

@media (max-width: 768px) {
  div.page {
    display: flex;
    scroll-snap-align: start;
  }
  main {
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
  }
}

